import { api } from "../../services/api";
import * as Sentry from "@sentry/nextjs";
const getCompany = async (companyId) => {
  try {
    const response = await api.get("/company/get/" + companyId);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

const registerCompany = async (company) => {
  try {
    const response = await api.post("/company/register", company);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    console.log("Error: ", err?.response?.data);
    return { ...err.response.data, type: "error" };
  }
};

const updateCompany = async (company) => {
  try {
    const response = await api.put("/company/update/" + company.id, company);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    console.log("Error: ", err?.response?.data);
    return { ...err?.response?.data, type: "error" };
  }
};

const getCompanies = async (offset = 10, page = 1) => {
  try {
    const response = await api.get(
      "/company/companies?offset=" + offset + "&page=" + page,
    );
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    //console.log("Error: ", err.response.data);
    return null;
  }
};

const getCountries = async () => {
  try {
    const response = await api.get("/company/requirements");
    return response.data
      ? response.data.countries
      : [
          {
            name: "Moçambique",
            countryCode: 508,
            id: "60c70d07fa55814dd242420d",
          },
        ];
  } catch (err) {
    Sentry.captureException(err);
    //console.log("Error: ", err.response.data);
    return null;
  }
};

const getCategories = async () => {
  try {
    const response = await api.get("/company/requirements");
    return response.data
      ? response.data.categories
      : [
          {
            name: "Tecnologia e Telecomunicações",
            id: "60c70cb1fa55814dd242420c",
          },
        ];
  } catch (err) {
    Sentry.captureException(err);
    //console.log("Error: ", err.response.data);
    return null;
  }
};

const getProvinces = async () => {
  try {
    const response = await api.get("/company/requirements");
    return response.data
      ? response.data.provinces
      : [
          {
            name: "Maputo",
            country: "60c70d07fa55814dd242420d",
            id: "60c70d96fa55814dd242420e",
          },
        ];
  } catch (err) {
    Sentry.captureException(err);
    //console.log("Error: ", err.response.data);
    return null;
  }
};

const getCities = async () => {
  try {
    const response = await api.get("/company/requirements");
    return response.data
      ? response.data.cities
      : [
          {
            name: "Cidade de Maputo",
            province: "60c70d96fa55814dd242420e",
            id: "60c70e38fa55814dd242420f",
          },
        ];
  } catch (err) {
    Sentry.captureException(err);
    //console.log("Error: ", err.response.data);
    return null;
  }
};

const checkSubdomain = async (subDomain) => {
  try {
    const response = await api.get("/company/subdomain/" + subDomain);
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    console.log("Error: ", err.response.data.message);
    return { type: "error", message: err.response.data.message };
  }
};

const ActiveOrDeactiveSubscription = async (id, isActive) => {
  try {
    const response = await api.put("/company/subscription/" + id, {
      isActive: !isActive,
    });
    return response.data;
  } catch (err) {
    Sentry.captureException(err);
    console.log("Error: ", err?.response?.data);
    return { ...err.response.data, type: "error" };
  }
};

module.exports = {
  getCompany,
  registerCompany,
  updateCompany,
  getCompanies,
  getCountries,
  getCategories,
  getProvinces,
  getCities,
  checkSubdomain,
  ActiveOrDeactiveSubscription,
};
