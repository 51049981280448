import Router, { useRouter } from "next/router";
import { api } from "../services/api";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import { createContext, useEffect, useState } from "react";
import Fc from "../actions/front-end";
import { recoverUserInformation, signInRequest } from "../services/auth";

import * as Sentry from "@sentry/nextjs";
import backEndActions from "../actions/back-end/companies";
export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const router = useRouter();
  const [user, setUser] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const companyId = Fc.chooseCompanyId(router.query.companyId, companyData?.id);
  const isAuthenticated = !!user;

  useEffect(() => {
    async function fetchData() {
      const { "fidli.token.dev": token } = parseCookies();
      if (token) {
        const responseData = await recoverUserInformation(token);
        setUser(responseData);
      }
    }
    fetchData();
  }, []);
  const getCompany = async (companyId) => {
    const companyData = await backEndActions.getCompany(companyId);
    setCompanyData(companyData?.company);
  };
  useEffect(() => {
    if (Fc.isValidId(router?.query?.companyId)) {
      getCompany(router.query.companyId);
      setCookie(null, "company.Id", router.query.companyId);
    } else {
      const { "company.Id": companyId } = parseCookies();
      if (companyId) {
        getCompany(companyId);
      }
    }
  }, [router.query.companyId]);

  const signIn = async ({ email, password }) => {
    const notExistsErrorField = document.getElementById("notExistsError");
    const loginErrorField = document.getElementById("loginError");
    const { token, user, err } = await signInRequest({ email, password }); // Make request and return's the user and token
    //
    const status = parseInt(err.response ? err.response.status : "200");
    if (err === "" && status === 200) {
      const { "fidli.token.dev": theToken } = parseCookies();
      if (theToken && theToken.length > 0) {
        console.log("I have a token here...");

        destroyCookie(undefined, "fidli.token.dev");
        setCookie(undefined, "fidli.token.dev", "", { maxAge: -1 });

        //console.log("Deleted token: ", theToken)
      }

      setCookie(undefined, "fidli.token.dev", token, {
        path: "/",
        secure: false,
        // httpOnly: false,
        SameSite: "None",
        maxAge: 24 * 60 * 60 /* 1 day*/,
      });

      api.defaults.headers["Authorization"] = `Bearer ${token}`; // Set's the token in axios Bearer for evertime he makes the request
      setUser(user); // Set's user data to the main state
      Sentry.setUser({ username: user?.username });
      if (user.isStaff) {
        Router.push("/super-admin"); // Call super admin dashboard
      }

      if (user.isManager) {
        Router.push("/local-details?id=" + user.store);
      }

      if (user.isAdmin) {
        Router.push("/"); // Call the next page to be called
      }
    } else {
      if (status === 404) {
        let array = err.response.data.message.split(" ");
        let finalText =
          array[0] +
          " " +
          array[1] +
          " " +
          array[2] +
          " " +
          array[3] +
          " " +
          array[4] +
          " " +
          array[5] +
          " " +
          array[6] +
          " <br/>" +
          array[7] +
          " " +
          array[8];

        notExistsErrorField.innerHTML = finalText;
        setTimeout(() => {
          notExistsErrorField.innerHTML = "";
        }, 5000);
      } else {
        loginErrorField.innerHTML = err.response
          ? err.response.data.message
          : "Erro de conexão com o servidor";
        setTimeout(() => {
          loginErrorField.innerHTML = "";
        }, 5000);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        signIn,
        companyData,
        companyId,
        getCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
