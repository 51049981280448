import axios from "axios";
import { parseCookies } from "nookies";

export function getAPIClient(ctx) {
  const { "fidli.token.dev": token } = parseCookies(ctx);

  const api = axios.create({
    baseURL: "https://dev.fidli.cloud",
  });

  api.interceptors.request.use((config) => {
    return config;
  });

  if (token) {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  }
  return api;
}
