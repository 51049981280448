import { api } from "../services/api";
import * as Sentry from "@sentry/nextjs";

export const signInRequest = async (signInRequestData) => {
  const { email, password } = signInRequestData; //Destruct email and password

  try {
    const response = await api.post("/users/authentication/signin", {
      username: email,
      password: password,
    });
    const data = response.data;
    return {
      token: data.token,
      user: data.user,
      err: "",
    };
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
    return {
      err,
    };
  }
};

export const recoverUserInformation = async (token) => {
  //Get user by toke and return
  try {
    api.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await api.get("/users/authentication/get/data");
    return response.data.user;
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
    return {
      company: {
        name: "Nome da Empresa",
        photo: "https://loremflickr.com/90/90/brand",
      },
      email: "usuario@email.com",
      firstName: "Nome",
      lastName: "Apelido",
      username: "nomedousuario",
    };
  }
};
