import { api } from "../../services/api";

export const getCorporateCompanies = async (companyId, page) => {
  try {
    page = page <= 1 ? 1 : page;
    const params = new URLSearchParams({ page: String(page), limit: 10 });
    const { data } = await api.get(
      `/loyalty-corporate-users/by/${companyId}?${params.toString()}`,
    );
    return { pagination: data["pagination"], items: data["corporateUsers"] };
  } catch (error) {
    console.error(error);
    return {
      pagination: {
        previous: 1,
        next: null,
        currentPage: 1,
        total: 1,
      },
      items: [],
    };
  }
};
export const deleteCorporateCompany = async (id, companyId) => {
  try {
    await api.delete(`/loyalty-corporate-users/${id}/${companyId}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const createPasswordForCorporateCompany = async (
  { password, password_confirmation },
  hash,
) => {
  try {
    const { data } = await api.patch(
      `/loyalty-corporate-users/update/password/${hash}`,
      {
        password,
        password_confirmation,
      },
    );
    return { data, error: null };
  } catch (error) {
    console.error(error);
    return { data: null, error };
  }
};
export const activeCorporateCompanyAccount = async (hash) => {
  try {
    const { data } = await api.patch(
      `/loyalty-corporate-users/update/active/${hash}`,
    );
    return { data, error: null };
  } catch (error) {
    console.error(error);
    return { data: null, error };
  }
};
export const signInCorporateCompany = async ({ email, password }) => {
  try {
    const { data } = await api.post(`/loyalty-corporate-users/signin`, {
      email: email,
      password: password,
    });
    return { data, error: null };
  } catch (error) {
    console.error(error);
    return { data: null, error };
  }
};
export const getCustumerCompanyById = async (id) => {
  try {
    const { data } = await api.get(`/loyalty-corporate-users/${id}`);
    return { data, error: null };
  } catch (error) {
    console.error(error);
    return {
      error,
      data: null,
    };
  }
};

export const getCustumerCompanyByIdAndCompanyId = async (id, companyId) => {
  try {
    const { data } = await api.get(
      `/loyalty-corporate-users/by-company/${id}/${companyId}`,
    );
    return { data, error: null };
  } catch (error) {
    console.error(error);
    return {
      error,
      data: null,
    };
  }
};
export const getCorporateUserCompanies = async (
  id,
  page,
  limit = 10,
  search = "",
) => {
  try {
    const { data } = await api.get(
      `/loyalty-corporate-users/get-corporate-user-companies/${id}?page=${page}&limit=${limit}&search=${search}`,
    );
    return { data, error: null };
  } catch (error) {
    console.error(error);
    return {
      error,
      data: null,
    };
  }
};
