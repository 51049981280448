import Image from 'next/image';
import React, { useState, useEffect, useRef } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';

import { cssProperties } from "../../css/notification-dialog"; // Get the css properties
const useStyles = makeStyles((theme) => cssProperties(theme)) //// Set the css properties

const Loader = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClickOpen = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        // if (open) {
        //     const { current: descriptionElement } = descriptionElementRef;
        //     if (descriptionElement !== null) {
        //         descriptionElement.focus();
        //     }
        // }

        let bars = document.getElementsByTagName("header");
        let selectedBar = bars && bars.length > 0 ? bars[0] : null;

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }

            if (selectedBar) {
                selectedBar.style.display = "none";
            }
        } else {
            if (selectedBar) {
                selectedBar.style.display = "block";
            }
        }
    }, [open]);


    return (
        <Dialog open={open} PaperProps={{ 
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }
        }} style={{opacity: 0.70}}>
           <Image src={"/loader.gif"} width={200} height={200}/>
        </Dialog>
    );
}

export default Loader;