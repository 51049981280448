const cssProperties = (theme) => {
    return {
        dialogContent: {
            width: 360,
            bordeRadius: 13,
            textAlign: "center",
            paddingBottom: 10
        },
        container: {
            textAlign: 'center'
        },
        icon: {
            fontSize: '5.6rem'
        },
        title: {
            fontWeight: 800,
            fontSize: 16
        },
        contentContainer: {
            padding: '0 0 7% 0',
        },
        text: {
            fontSize: 13,
        },
        btn: {
            fontWeight: 900,
            textTransform: "capitalize"
        },
        line: {
            textDecoration: "underline",
            fontSize: 13
        }
    }
}

module.exports = { cssProperties }
