import React, { useContext, useState, useEffect } from "react";
import {
  getCustumerCompanyById,
  getCustumerCompanyByIdAndCompanyId,
  signInCorporateCompany,
} from "../actions/back-end/company-corporate";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import { recoverUserInformation } from "../services/auth";
import { useRouter } from "next/router";
import { api } from "../services/api";

export const CustumerAuth = React.createContext({});

interface CustumerStorageAuthProps {
  children: React.ReactNode;
}

export function CustumerStorageAuth({ children }: CustumerStorageAuthProps) {
  const [custumerUser, setCustumerUser] = useState<any>(null);
  const router = useRouter();
  const corporateUserId = router.query.corporateUserId;
  const companyId = router.query.companyId;

  useEffect(() => {
    const getUserData = async (corporateUserId: string | string[]) => {
      const { data } = await getCustumerCompanyById(corporateUserId);

      if (data) {
        setCustumerUser(data?.corporateUser);
      }
    };
    if (corporateUserId) {
      getUserData(corporateUserId);
    }
  }, [corporateUserId]);

  useEffect(() => {
    const getUserData = async (
      corporateUserId: string | string[],
      companyId: string | string[],
    ) => {
      const { data } = await getCustumerCompanyByIdAndCompanyId(
        corporateUserId,
        companyId,
      );
      console.log("[dados de cliente empresa]", data);
      if (data) {
        setCustumerUser(data?.corporateUser);
      }
    };
    if (corporateUserId && companyId) {
      getUserData(corporateUserId, companyId);
    }
  }, [corporateUserId, companyId]);

  async function signIn({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    const { data, error } = await signInCorporateCompany({ email, password });

    if (data) {
      setCookie(undefined, "fidli.token.dev", data.token, {
        path: "/",
        secure: false,
        SameSite: "None",
        maxAge: 24 * 60 * 60 /* 1 day*/,
      });
      api.defaults.headers["Authorization"] = `Bearer ${data.token}`; // Set's the token in axios Bearer for evertime he makes the request
      router.push({
        pathname: "/portal",
        query: {
          corporateUserId: data.user.id,
        },
      });
    }

    return { data, error };
  }

  const valueStorage = {
    custumerUser,
    setCustumerUser,
    signIn,
    user: custumerUser,
  };

  return (
    <CustumerAuth.Provider value={valueStorage}>
      {children}
    </CustumerAuth.Provider>
  );
}

export const useCustumerUser = () => {
  const context = useContext(CustumerAuth);

  if (!context) {
    throw new Error(
      "useCustumerUser deve ser usado dentro do CustumerStorageAuth",
    );
  }
  return context;
};
